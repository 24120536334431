import React from "react";

import { FcApproval } from "react-icons/fc";
import UserHeader from "../../components/UserHeader/UserHeader";
import { withTranslation } from "react-i18next";

class PasswordSuccess extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            partnerId: null
        };

    }
    componentDidMount() {
        const searchParams = new URLSearchParams(this.props.location.search);
        const partnerId = Number(searchParams.get('partner_id'));
        this.setState({ partnerId });
    }


    render() {
        //    console.log(this.state.partnerId)
        const { t } = this.props;
        return (
            <>
                <UserHeader partner_id={this.state.partnerId}></UserHeader>
                <div className="expire-wrp">
                    <div><h1>{t('title.success')}</h1></div>
                    <div>
                        <FcApproval
                            size={250}
                        ></FcApproval>
                        <div className="expire-text">{t('messages.signup_complete')}</div>
                    </div>
                </div>
            </>
        );
    }
};

export default withTranslation()(PasswordSuccess);
