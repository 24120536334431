import React from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { connect } from 'react-redux';
import { compose } from "redux";
import { Link } from 'react-router-dom'
import { withTranslation } from "react-i18next";
import Loader from "../../../components/Loader/Loader";

import { userActions, alertActions } from '../../../actions';
import { apiService } from "../../../services/api_service";
import { validatePasswordCriteria } from "../../../helpers/password";
import { getPartnerUrlPrefix } from "../../../helpers/partner";
import { EyeIconHide, EyeIconShow } from "../../../components/Icons/EyeIcons";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props)
    this.props.logout(this.props.history);

    this.state = {
      email: "",
      newpassword: "",
      confirmpassword: "",
      submitted: false,
      hide: true,
      loading: false,
      emailError: false,
      passwordError: false,
      passwordFormat: false,
      hide1: true,
      confirmError: false,
      token: ""
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleUnhidePassword = this.handleUnhidePassword.bind(this);
    this.handleUnhideConfirmPass = this.handleUnhideConfirmPass.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handlePasswordValidation = this.handlePasswordValidation.bind(this);
  }

  componentWillMount() {
    this.setState({ loading: true })
  }

  async componentDidMount() {
    let partner_id = this.props.partner
    // set default color
    if (partner_id == '7') {
      document.getElementById("bodyID").style.setProperty('--themecolor', '#000000');
      document.getElementById("bodyID").style.setProperty('--themefontcolor', '#000000');
      document.getElementById("bodyID").style.setProperty('--liteThemecolor', '#a6a6a6');
      document.getElementById("bodyID").style.setProperty('--liteBackgroundColor', '#e6e6e6');
      document.getElementById("bodyID").style.setProperty('--liteButtonBackground', '#818181');
      document.getElementById("bodyID").style.setProperty('--filterSvg', ' invert(0%) sepia(3%) saturate(6%) hue-rotate(50deg) brightness(30%) contrast(100%)');
    } if (partner_id == '45') {
      document.getElementById("bodyID").style.setProperty('--themecolor', '#080B37');
      document.getElementById("bodyID").style.setProperty('--themefontcolor', '#080B37');
      document.getElementById("bodyID").style.setProperty('--liteThemecolor', '#636ce9');
      document.getElementById("bodyID").style.setProperty('--liteBackgroundColor', '#d2d4f9');
      document.getElementById("bodyID").style.setProperty('--liteButtonBackground', '#3642e2');
      document.getElementById("bodyID").style.setProperty('--filterSvg', ' invert(0%) sepia(3%) saturate(6%) hue-rotate(50deg) brightness(30%) contrast(100%)');
    }
    else if (partner_id === 46) {
      document
        .getElementById("bodyID")
        .style.setProperty("--themecolor", "#2c2c2c");
      document
        .getElementById("bodyID")
        .style.setProperty("--themefontcolor", "#2c2c2c");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteThemecolor", "#b3b3b3");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteBackgroundColor", "#f2f2f2");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteButtonBackground", "#e6e6e6");
      document
        .getElementById("bodyID")
        .style.setProperty("--navtext", "#ffffff");
      document
        .getElementById("bodyID")
        .style.setProperty("--headpadding", "22px 0px");
    }
    else if (partner_id === 48) {
      document
        .getElementById("bodyID")
        .style.setProperty("--themecolor", "#65C0B6");
      document
        .getElementById("bodyID")
        .style.setProperty("--themefontcolor", "#65C0B6");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteThemecolor", "#c9e9e5");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteBackgroundColor", "#edf8f6");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteButtonBackground", "#b7e1dc");
      document
        .getElementById("bodyID")
        .style.setProperty("--headpadding", "9px 0px");
    }
    else if (partner_id === 11) {
      document
        .getElementById("bodyID")
        .style.setProperty("--themecolor", "#76D3CA");
      document
        .getElementById("bodyID")
        .style.setProperty("--themefontcolor", "#76D3CA");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteThemecolor", "#c5ece8");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteBackgroundColor", "#ecf9f7");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteButtonBackground", "#d8f3f0");
      document
        .getElementById("bodyID")
        .style.setProperty("--headpadding", "22px 0px");
    } else if (partner_id === 15) {
      document
        .getElementById("bodyID")
        .style.setProperty("--themecolor", "#243144");
      document
        .getElementById("bodyID")
        .style.setProperty("--themefontcolor", "#243144");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteThemecolor", "#bcc9dc");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteBackgroundColor", "#eef1f6");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteButtonBackground", "#cdd6e5");
      document
        .getElementById("bodyID")
        .style.setProperty("--headpadding", "12px 0px");
    }
    else if (partner_id === 50) {
      document
        .getElementById("bodyID")
        .style.setProperty("--themecolor", "#05aaff");
      document
        .getElementById("bodyID")
        .style.setProperty("--themefontcolor", "#05aaff");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteThemecolor", "#b3e6ff");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteBackgroundColor", "#cceeff");
      document
        .getElementById("bodyID")
        .style.setProperty("--navtext", "#ffffff");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteButtonBackground", "#cdd6e5");
      document
        .getElementById("bodyID")
        .style.setProperty("--headpadding", "15px 0px");
    }
    else if (partner_id === 51) {
      document
        .getElementById("bodyID")
        .style.setProperty("--themecolor", "#b34944");
      document
        .getElementById("bodyID")
        .style.setProperty("--themefontcolor", "#b34944");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteThemecolor", "#f8eded");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteBackgroundColor", "#f8eded");
      document
        .getElementById("bodyID")
        .style.setProperty("--navtext", "#ffffff");
      document
        .getElementById("bodyID")
        .style.setProperty("--liteButtonBackground", "#FCFCFA");

    }
    else if (partner_id === 52) {
      document.getElementById("bodyID").style.setProperty("--themecolor", "#6037d9");
      document.getElementById("bodyID").style.setProperty("--themefontcolor", "#6037d9");
      document.getElementById("bodyID").style.setProperty("--liteThemecolor", "#b7a5ee");
      document.getElementById("bodyID").style.setProperty("--liteBackgroundColor", "#d7cdf6");
      document.getElementById("bodyID").style.setProperty("--liteButtonBackground", "#aa81ff");
      document.getElementById("bodyID").style.setProperty("--navtext", "#ffffff");
      document.getElementById("bodyID").style.setProperty("--headpadding", "22px 0px");
    }
    else {
      document.getElementById("bodyID").style.setProperty('--themecolor', '#09a79e');
      document.getElementById("bodyID").style.setProperty('--themefontcolor', '#09a79e');
      document.getElementById("bodyID").style.setProperty('--liteThemecolor', '#09a79e69');
      document.getElementById("bodyID").style.setProperty('--liteBackgroundColor', '#09a79e17');
      document.getElementById("bodyID").style.setProperty('--liteButtonBackground', '#0ac2b9');
      document.getElementById("bodyID").style.setProperty('--filterSvg', '');
    }

    if (!this.props.match.params.token) {
      this.props.history.push("/signin");
    }

    let token = decodeURIComponent(this.props.match.params.token)

    if (!token) {
      this.props.history.push("/linkexpired")
    } else {
      let validate_token = await apiService.TokenValidate({
        authorizationToken: `Bearer ${token}`,
      });

      if (!validate_token) {
        this.props.error("Session Expired");
        this.props.history.push("/linkexpired")
      }
      this.setState({ token, email: validate_token?.data?.email || "", loading: false });
    }


  }

  componentDidUpdate() {
    if (this.props.alert.message) {
      console.log(this.props.alert);
      setTimeout(() => {
        this.props.clearAlerts();
      }, 5000)
    }
  }


  handlePasswordValidation(name, value) {
    let data = {}
    if (name === "newpassword") {
      const error = validatePasswordCriteria(value);
      data["passwordFormat"] = error.length
    }

    if (name === "confirmpassword") {
      data["confirmError"] = this.state.newpassword !== value
    }

    return Object.keys(data).length ? data : null
  }

  handleChange(e) {
    const { name, value } = e.target;
    let data = { [name]: value }

    let validatePasswordData = this.handlePasswordValidation(name, value)

    if (validatePasswordData) {
      data = { ...data, ...validatePasswordData }
    }

    this.setState({ ...data });
  }

  handleKeyPress(e) {
    const { name, value } = e.target;

    let validatePasswordData = this.handlePasswordValidation(name, value)

    if (validatePasswordData) {
      this.setState({ ...validatePasswordData });
    }

    if (e.charCode === 13) {
      if (this.state.newpassword && this.state.confirmpassword && !this.state.passwordFormat) {
        e.preventDefault()
        this.handleSubmit()
      } else {
        if (!this.state.newpassword) {
          this.setState({ passwordError: true })
        } else {
          if (!this.state.confirmpassword) {
            this.setState({ passwordError: false, confirmError: true })
          }
        }
      }
    }
  }

  async handleSubmit() {
    const {
      submitted, passwordError, passwordFormat, newpassword, confirmpassword
    } = this.state

    if (submitted || passwordError || passwordFormat || !newpassword || !confirmpassword ||
      (newpassword.length && confirmpassword.length && newpassword !== confirmpassword)
    ) {
      return
    }

    if (this.state.newpassword === this.state.confirmpassword && !this.state.passwordFormat) {
      this.setState({ submitted: true });
      const { email, newpassword } = this.state;
      if (email && newpassword) {
        let result = await this.props.resetPassword(this.props.history, email, btoa(newpassword), this.props.partner,
          this.state.token
        )

        if (result?.statusCode === 400) {

          if (result?.data?.code === "choose_different_password") {
            this.props.error(result?.message || this.props.t('choose_different_password'));
            this.setState({ submitted: false });
            return;
          }

          this.props.history.push("/linkexpired");
          return;
        }

        console.log("result", result)
        this.props.success("Successfully updated the password")
      }
    } else {
      if (this.state.confirmpassword) {
        this.setState({ confirmError: true })
      } else {
        this.setState({ passwordError: true })
      }

    }
  }
  handleUnhidePassword() {
    this.setState({ hide: !this.state.hide })
  }
  handleUnhideConfirmPass() {
    this.setState({ hide1: !this.state.hide1 })
  }
  handleClose = () => {
    this.props.clearAlerts();
  }
  render() {
    const { partner, alert, t } = this.props;
    const { email, newpassword, confirmpassword, submitted, loading, hide, emailError, passwordError, confirmError, passwordFormat, hide1 } = this.state;


    return (
      <div className="formPart forgotForm">
        {alert.message &&
          <div className={`alert ${alert.type}`} style={{ marginBottom: 0 }}>
            {alert.message}
            <button class="close" onClick={this.handleClose} >x</button>
          </div>
        }
        <Loader loading={loading} />
        <h1>{t('heading.change_password')}</h1>
        <h6>{t('password_expired')}</h6>
        <Form>
          <Form.Group className="sign-field">
            <Form.Control placeholder={t('placeholder.email')} type="text" name="email" value={email} disabled required />
            <Form.Text className="text-muted">
              {emailError ? "t('validations.email')" : null}
            </Form.Text>
          </Form.Group>
          <Form.Group className="sign-field">
            <div className="passwordfeild">
              <Form.Control placeholder={t('placeholder.new_password')} type={this.state.hide ? "password" : "text"} name="newpassword" value={newpassword} onChange={this.handleChange} onKeyPress={this.handleKeyPress} required />
              <span className="eyeicon" onClick={this.handleUnhidePassword}>
                {hide ? <EyeIconHide></EyeIconHide> : <EyeIconShow></EyeIconShow>}
              </span>
            </div>
            <Form.Text className="text-muted">
              {passwordError ? t('validations.password') : null}
              {passwordFormat ? t('password_criteria.portal_validation') : null}
            </Form.Text>
          </Form.Group>
          <Form.Group className="sign-field">
            <div className="passwordfeild">
              <Form.Control placeholder={t('placeholder.confirm_password')} type={this.state.hide1 ? "password" : "text"} name="confirmpassword" value={confirmpassword} onChange={this.handleChange} onKeyPress={this.handleKeyPress} required />
              <span className="eyeicon" onClick={this.handleUnhideConfirmPass}>
                {hide1 ? <EyeIconHide></EyeIconHide> : <EyeIconShow></EyeIconShow>}
              </span>
            </div>
            <Form.Text className="text-muted">
              {confirmError ? t('validations.confirm_password') : null}
            </Form.Text>
          </Form.Group>
          <Button className="continuebtn" onClick={this.handleSubmit}
            disabled={submitted || passwordError || passwordFormat || !newpassword || !confirmpassword
              || (newpassword.length && confirmpassword.length && newpassword !== confirmpassword)
            }
          >
            {t('buttons.change_password')}
          </Button>
          {<Link className="backtologin" to={`${getPartnerUrlPrefix(partner)}/signin`}>{t('back_to_login')}</Link>}
        </Form>
      </div>
    );
  }
};

function mapState(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return { loggingIn, alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  success: alertActions.success,
  error: alertActions.error,
  resetPassword: userActions.resetPassword,
  login: userActions.login,
  logout: userActions.logout
}

export default compose(
  connect(mapState, actionCreators),
  withTranslation()
)(ChangePassword);
