import React from "react";
import { connect } from 'react-redux';
import { Table, Modal, Button, Spinner } from "react-bootstrap";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { alertActions } from '../../actions/alert-action';
import Checkbox from "../Checkbox/Checkbox";
import {
    BsPencilSquare,
    BsFillTrashFill,
    BsEye,
    BsEnvelope
} from "react-icons/bs";

class UserTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: false,
            show1: false,
            kit_id: '',
            trackingHistory: [],
            history_width: '100%',
            open: true,
            loading: false,
            show2: false
        }
    }

    handleClose = () => this.setState({ show: false })

    handleOpen = () => this.setState(!this.state.open);

    handleClose1 = () => this.setState({ show1: false })
    handleShow1 = () => this.setState({ show1: true })
    handleClose2 = () => this.setState({ show2: false })
    handleShow2 = () => this.setState({ show2: true })

    render() {
        let { loading_status, t } = this.props;
        let { show, show1, kit_id, trackingHistory, history_width, loading, open, show2 } = this.state
        return (
            <div className="cmnTable">
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>{t('username')}</th>
                            <th>{t('name')}</th>
                            <th>{t('email_address')}</th>
                            <th>{t('phone')}</th>
                            <th>{t('type')}</th>
                            <th>{t('action')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading_status && this.props.userDetails.length <= 0 ? <tr style={{ textAlign: "center" }}><td colSpan={10}><h5>{t('users_not_present')}</h5></td></tr> : null}

                        {
                            loading_status ? <Spinner className='spinnerClass' animation="border" variant="info" style={{ marginLeft: '250%', padding: '20px', marginTop: '30px', marginBottom: '30px' }} /> :
                                this.props.userDetails && this.props.userDetails.map((user) => (
                                    <tr key={user._id}>
                                        <td>{user.user_name}</td>
                                        <td>{user.first_name + " " + user.last_name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.phone}</td>
                                        <td>{user.type == "clinic" ? "Clinic/ Sub-Partner" : camelize(user.type)}</td>
                                        <td>
                                            <div className="action-icons" style={{ display: "inline-flex" }}>
                                                <button onClick={() => this.props.resendEmail("email", user._id)} id={user._id} className="tableviewLink" title={t('resend_email')} disabled={user.email_confirmed ? true : false}>
                                                    {/* <img src="images/eye-image.png"></img> */}
                                                    <BsEnvelope
                                                        size={18}
                                                        color={user.email_confirmed ? 'grey' : ''}
                                                    ></BsEnvelope>
                                                </button>
                                                <button onClick={() => this.props.handleOperation("view", user._id)} id={user._id + "view"} className="tableviewLink" title={t('view_user')}>
                                                    {/* <img src="images/eye-image.png"></img> */}
                                                    <BsEye
                                                        size={18}
                                                    // color={user.user_type!='Practitioner' ? 'grey':''}
                                                    ></BsEye>
                                                </button>
                                                <button onClick={() => this.props.handleOperation("edit", user._id)} id={user._id + "edit"} className="tableviewLink" title={t('edit_user')}>
                                                    <BsPencilSquare
                                                        size={18}
                                                    // color={user.user_type!='Practitioner' ? 'grey':''}
                                                    ></BsPencilSquare>
                                                </button>
                                                <button onClick={() => this.props.handleOperation("delete", user._id)} id={user._id + "delete"} className="tableviewLink" title={t('delete_user')}>
                                                    <BsFillTrashFill
                                                        size={18}
                                                        color={'red'}
                                                    ></BsFillTrashFill>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                        }


                    </tbody>
                </Table>
                <Modal show={show2} onHide={this.handleClose2} centered className="trackingModal" size="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>{t('kit_tracking_history')}: {kit_id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{t('no_history_found')}</Modal.Body>

                </Modal>

                <Modal show={show} onHide={this.handleClose} centered className="trackingModal" >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('kit_tracking_history')}: {kit_id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="tracking-wrp st-resultReady" >
                            <ul className="list-unstyled statustypelist">
                                {trackingHistory.map((kit) => (
                                    <li className="active">{kit.status}</li>
                                ))}
                            </ul>
                            <div className="trackingbar-wrp">
                                <div className="trackingbar">
                                    <div className="trackingstatus" style={{ width: '100%' }}></div>
                                </div>
                                <div className="spanwrp">
                                    {trackingHistory.map((kit) => (

                                        <span style={{ left: kit.width }}><img src="images/statuschecked.svg"></img></span>

                                    ))}
                                </div>

                            </div>
                            <ul className="list-unstyled">
                                {trackingHistory.map((kit) => (
                                    <li className="active" style={{ width: kit.width, }}>{moment(kit.date).format("YYYY-MM-DD")}</li>
                                ))}
                            </ul>
                        </div>



                    </Modal.Body>

                </Modal>


                <Modal show={show1} onHide={this.handleClose1} centered className="trackingModal releaseModal" >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('release_results_generate_pdf')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Checkbox title={t('release_dna_results')}></Checkbox>
                        <Checkbox title={t('release_epi_results')}></Checkbox>
                        <Checkbox title={t('release_dna_pdf')}></Checkbox>
                        <Checkbox title={t('release_epi_pdf')}></Checkbox>


                    </Modal.Body>
                    {open ?
                        <Modal.Footer>
                            <div>
                                <Button className="bgBtn mr-3" onClick={this.handleOpen}>
                                    {t('buttons.submit')}
                                </Button>
                            </div>
                        </Modal.Footer>
                        :
                        <Modal.Footer>
                            <h5>{t('release_dna_results_question')}</h5>
                            <div>
                                <Button className="bgBtn mr-3" onClick={() => {
                                    this.handleClose1()
                                    this.handleOpen()
                                }}>
                                    {t('buttons.confirm')}
                                </Button>
                                <Button className="outerlineBtn" onClick={() => {
                                    this.handleClose1()
                                    this.handleOpen()
                                }}>
                                    {t('buttons.cancel_caps')}
                                </Button>
                            </div>
                        </Modal.Footer>
                    }
                </Modal>
            </div>
        );
    }
}

function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toUpperCase() : word.toUpperCase();
    }).replace(/\s+/g, ' ');
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    success: alertActions.success,
    error: alertActions.error

}
export default compose(
    connect(mapState, actionCreators),
    withTranslation()
)(UserTable);
