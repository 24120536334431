import React from "react";
import { withTranslation } from "react-i18next";

import PieChart from "../../components/Graphs/pieChart/pieChart";
import { getColorCode } from "../../helpers/getColorCode";

const CongenitalSummary = ({ num, summary, t }) => {

    return (
        <>
            <div className="reportSummary-wrp">
                <div className="congenital-summary">
                    <div className="left-part">
                        <PieChart summary={summary} num={num} graphColor={getColorCode(num)} />
                    </div>
                    <div className="right-part">
                        <p className="summary-heading" style={{ color: getColorCode(num) }}>{summary.title}</p>
                        {summary.intro &&
                            <div className="summary-text" dangerouslySetInnerHTML={{ __html: summary.intro }} ></div>
                        }
                    </div>
                </div>
                <hr />
                <h6>{t('title.summary')}</h6>
                {
                    summary.title ?
                        <p>{t('text.practitioner_report_result1', {
                            score: num,
                            high_score: summary.total && summary.total.length == 2 ? summary.total[1] : "2",
                            result: summary.title
                        })}</p>
                        :
                        <p>{t('text.practitioner_report_result1', {
                            score: summary.total && summary.total.length == 2 ? summary.total[0] : "20",
                            high_score: summary.total && summary.total.length == 2 ? summary.total[1] : "2",
                            outcome: num
                        })}</p>
                }
            </div>
        </>
    )
}

export default withTranslation()(CongenitalSummary);
