import React from "react";
import { Spinner, Modal, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";

import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { currentActions } from "../../actions";

import AsyncLookup from "../AsyncLookup/AsyncLookup";
import { getSearchBarOption } from "../../constants";

const style = {
  blockquote: {
    fontStyle: "italic",
    fontSize: ".75rem",
    margin: "1rem 0",
  },
  label: {
    fontSize: ".75rem",
    fontWeight: "bold",
    lineHeight: 2,
  },
};

class PageHead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      hide: false,
      name: "",
      IsMenuOpen: false,
      ariaFocusMessage: "",
      selected: getSearchBarOption(),
      detailsGetAPICall: false,
      inputValue: "",
      useSelectDropdown: false,
      // PDF
      showPdfModal: false,
      userEmail: false,
      practitionerEmail: false,
      selectOptionError: false,
      pdfModalFooterOpen: true,
      pdfLoader: false
    };
    this.newselectHandler = this.newselectHandler.bind(this);
  }

  async componentWillReceiveProps(nextProps) {
    if (!this.state.useSelectDropdown) {
      let current = nextProps.current?.currentVal;
      const currentValue = current?.value;
      if (
        current &&
        currentValue &&
        currentValue !== "select" &&
        currentValue !== this.state.selected?.value &&
        nextProps?.user?._id
      ) {
        let current = nextProps.current.currentVal;
        if (!this.props.is_disabled) {
          if (
            ["/practitionersreport", "/congenitalreport", "/diseaserisk"].includes(
              window.location.pathname
            )
          ) {
            console.log("currentValue 57", current);
            this.newselectHandler(getSearchBarOption());
            this.setState({
              selected: getSearchBarOption(),
            });
          } else {
            console.log("currentValue 63", current);
            this.newselectHandler(current);
            this.setState({ selected: current });
          }
        }
      }
    }
  }

  componentDidUpdate() {
    // Dont Use componentDidUpdate due to infinite loop in DetailsGetAPI
  }

  async newselectHandler(e) {
    this.setState({ useSelectDropdown: true });
    if (!e) {
      this.setState({
        selected: getSearchBarOption(),
        hide: true,
      });
      this.props.remove("", "", this.props.page);
      await this.props.DetailsGetAPI("select", "");
      this.props?.onUserClear?.();
      return;
    }

    let user_id = e.value;
    let name = e.label;

    await this.props.DetailsGetAPI(user_id, name);
    this.setState({ selected: e });
    this.props.add(user_id, name, this.props.page);
    if (this.props.handleTitle) {
      this.props.handleTitle();
    }

    // lifestyleData
  }

  // PDF Modal Handlers
  showExportPdfModal = () => {
    this.setState({ showPdfModal: true });
  }

  handleClosePdfModal = () => {
    this.setState({
      showPdfModal: false,
      userEmail: false,
      practitionerEmail: false,
      selectOptionError: false,
      pdfModalFooterOpen: true,
    });
  }

  handlePdfSubmit = () => {
    if (!this.state.userEmail && !this.state.practitionerEmail) {
      this.setState({ selectOptionError: true });
      return;
    }
    this.setState({
      pdfModalFooterOpen: !this.state.pdfModalFooterOpen,
      selectOptionError: false,
    });
  }

  getReleasePdfBody = () => {
    const body = {
      practitioner_mail: this.state.practitionerEmail,
      user_mail: this.state.userEmail,
    };
    if (this.props.reportType === "disease_risk") {
      body.user_id = Buffer.from(String(this.state.selected?.value)).toString("base64");
      return body;
    }

    if (this.props.pdfType === "single") {
      body.user_id = Buffer.from(String(this.state.selected?.value)).toString("base64");
      return body;
    }

    body.user_id1 = Buffer.from(String(this.state.selected?.value)).toString("base64");
    body.user_id2 = Buffer.from(String(this.props.compareUser)).toString("base64");
    return body;

  }

  releasePDF = async () => {
    this.setState({ pdfLoader: true });
    const body = this.getReleasePdfBody();

    const releasePdf = await this.props.reportButtonCallback(body).catch((err) => {
      console.log("Error in releasePDF", err);
      this.setState({ pdfLoader: false });
    });
    this.setState({ pdfLoader: false });
    this.handleClosePdfModal();
  }

  onMenuOpen = () => this.setState({ isMenuOpen: true });
  onMenuClose = () => this.setState({ isMenuOpen: false });
  onFocus = () => { };

  render() {
    const { title, buttonTitle, handleAdd, reportType, t } = this.props;
    let { selected, isMenuOpen, ariaFocusMessage, userEmail, practitionerEmail, selectOptionError, pdfModalFooterOpen, pdfLoader } = this.state;

    const pdfModal = (
      <Modal show={this.state.showPdfModal} centered className="trackingModal releaseModal" size="sm" onHide={this.handleClosePdfModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Label>
                {this.props.pdfType === "combined" ? (
                  <span dangerouslySetInnerHTML={{
                    __html: t('labels.single_pdf_confirmation',
                      {
                        title: title,
                        customer: selected?.label
                      })
                  }} />
                ) : (
                  <span dangerouslySetInnerHTML={{
                    __html: t('labels.combined_pdf_confirmation',
                      {
                        title: title,
                        customer_1: selected?.label,
                        customer_2: this.props.compareUserName
                      })
                  }} />
                )}
              </Form.Label>
              <Form.Check
                type="checkbox"
                label="Customer(s)"
                checked={userEmail}
                onChange={() => this.setState({ userEmail: !userEmail })}
              />
              <Form.Check
                type="checkbox"
                label="Practitioner"
                checked={practitionerEmail}
                onChange={() => this.setState({ practitionerEmail: !practitionerEmail })}
              />
              <Form.Text className="text-muted">
                {selectOptionError ? t('validations.select_option') : null}
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        {pdfModalFooterOpen ? (
          <Modal.Footer>
            <div>
              <Button className="bgBtn mr-3" onClick={this.handlePdfSubmit}>
                {t('buttons.submit')}
              </Button>
            </div>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <h5>{t('pdf_report_confirmation')}</h5>
            <div>
              <Button className="bgBtn mr-3" onClick={() => { this.releasePDF(); }}>
                {pdfLoader && (
                  <Spinner
                    className="spinnerClass"
                    animation="border"
                    variant="light"
                    size="sm"
                    style={{ float: "right", marginLeft: "25px" }}
                  />
                )}
                {t('buttons.confirm')}
              </Button>
              <Button
                className="bgBtn mr-3"
                onClick={() => { this.handleClosePdfModal(); }}
              >
                {t('buttons.cancel_caps')}
              </Button>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    );

    return (
      <>
        {this.state.showPdfModal ? pdfModal : null}

        <div className="innerpagesHead">
          <div className="row justify-content-between align-items-end">
            <div className="col-12 col-sm-auto">
              <h1>{title}</h1>
              <BreadCrumb title={title}></BreadCrumb>
            </div>
            {this.props.showReportButton && (
              <div className="addBtn-home">
                <button className="exportBtn" onClick={this.showExportPdfModal}>
                  <i className="bi bi-upload"></i> {this.props.reportButtonTitle}
                </button>
              </div>
            )}

            {!this.props.is_disabled && (
              <div className="col-12 col-sm-auto d-flex">
                <ul className="list-unstyled">
                  <li>
                    <p
                      style={{
                        color: "black",
                        fontSize: "1.2em",
                        paddingRight: "5px",
                      }}
                    >
                      {t('labels.user')}:{" "}
                    </p>
                    {!!ariaFocusMessage && !!isMenuOpen && (
                      <blockquote style={style.blockquote}>
                        "{ariaFocusMessage}"
                      </blockquote>
                    )}
                    <AsyncLookup
                      ariaLiveMessages={this.onFocus()}
                      inputId="aria-example-input"
                      value={selected}
                      onChange={(e) => this.newselectHandler(e)}
                      inputValue={this.state.inputValue}
                      onInputChange={(inputValue) => {
                        this.setState({ inputValue });
                      }}
                      type={this.props.type || "genetic"}
                      {...this.props}
                    />
                  </li>
                </ul>
                {this.props.showButton && (
                  <>
                    <div className="col-12 col-sm-auto">
                      <button className="addBtn" onClick={handleAdd}>
                        <i className="bi bi-person-plus-fill"></i>
                        {t('buttons.add_user', { type: buttonTitle })}
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn, user } = state.authentication;
  const { alert, current } = state;
  return { loggingIn, alert, current, user };
}

const actionCreators = {
  add: currentActions.add,
  remove: currentActions.remove,
};

export default compose(
  connect(mapState, actionCreators),
  withTranslation()
)(PageHead);