import React from "react";
import PieChart from "../../components/Graphs/pieChart/pieChart";

const OverviewDetails = ({ overview, tabs, categorySelector }) => {
    // Function to split the overview array into chunks of 4
    const chunkArray = (array, size) => {
        let result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }

        // console.log("result", result)
        return result;
    };

    // Splitting the overview array into chunks of 4
    const overviewChunks = chunkArray(overview, 4);

    return (
        <>
            <div className="reportDetail-wrp">
                {overviewChunks.map((chunk, chunkIndex) => (
                    <div key={chunkIndex} style={{ display: "flex", justifyContent: "space-around", marginBottom: "20px" }}>
                        {chunk.map((items, index) => {
                            const tab = tabs.find((t) => t.key === items.type);
                            return (
                                // Using a combination of chunkIndex and index to create a unique key
                                <div key={`chunk-${chunkIndex}-item-${index}`} style={{ display: "flex", flexDirection: "column", width: "calc(100%/4)" }} onClick={() => categorySelector(tab)}>
                                    <h5 style={{ fontSize: 15, fontWeight: 500, marginTop: "5%", textAlign: "center", paddingBottom: "15px", cursor: "pointer" }}>
                                        {items.name}
                                    </h5>
                                    <PieChart className="pie-chart-overview" summary={items} num={items.score} />
                                    <p style={{ fontSize: 15, fontWeight: 500, marginTop: "5%", textAlign: "center", paddingTop: "15px", cursor: "pointer", }} >
                                        {items.title}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        </>
    );
};

export default OverviewDetails;
